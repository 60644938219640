var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CModalExtended',{staticClass:"modal-extended",attrs:{"title":_vm.tituloModal,"closeOnBackdrop":false,"color":"dark","size":"lg","show":_vm.modalActive},on:{"update:show":function($event){_vm.modalActive=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"add","shape":"square","disabled":_vm.isSubmit},on:{"click":_vm.submit}},[_c('div',[_c('CIcon',{attrs:{"name":"checkAlt"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("button.accept")))])],1)]),_c('CButton',{attrs:{"color":"wipe","shape":"square","disabled":_vm.isSubmit},on:{"click":function($event){return _vm.toggle(false)}}},[_c('CIcon',{attrs:{"name":"x"}}),_vm._v("  "+_vm._s(_vm.$t("button.cancel"))+" ")],1)]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',{staticClass:"card-simple"},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"9"}},[_c('CSelect',{attrs:{"size":"sm","addLabelClasses":"required text-right","label":_vm.$t('label.circuit'),"horizontal":{
                        label: 'col-sm-12 col-lg-5',
                        input: 'col-sm-12 col-lg-7',
                      },"options":_vm.circuitOptions,"value":_vm.$v.modalityCircuitInfo.CircuitId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.modalityCircuitInfo.CircuitId),"is-valid":_vm.hasError(_vm.$v.modalityCircuitInfo.CircuitId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.modalityCircuitInfo.CircuitId, "$model", $event)},"change":_vm.CircuitName}})],1),_c('CCol',{attrs:{"sm":"2"}},[_c('div',{staticClass:"d-flex align-items-start"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.add')+_vm.$t('label.modality')+_vm.$t(' ')+_vm.$t('label.circuit'),
                                placement: 'top-end'
                              }),expression:"{\n                                content: $t('label.add')+$t('label.modality')+$t(' ')+$t('label.circuit'),\n                                placement: 'top-end'\n                              }"}],staticClass:"d-flex align-items-center ml-2",attrs:{"color":"add","size":"sm","disabled":_vm.isSubmit},on:{"click":_vm.addCircuit}},[_c('CIcon',{attrs:{"name":"cil-plus"}})],1)],1)]),_c('CCol',{staticClass:"mt-2",attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"sm","fields":_vm.fields,"items":_vm.circuitosOptions,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"Nro",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.Nro)+" ")])]}},{key:"Details",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.Down'),
                                placement: 'top-end'
                              }),expression:"{\n                                content: $t('label.Down'),\n                                placement: 'top-end'\n                              }"}],attrs:{"color":"plan","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.bajar(item)}}},[_c('CIcon',{attrs:{"name":"cil-arrow-circle-bottom"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.Up'),
                                placement: 'top-end'
                              }),expression:"{\n                                content: $t('label.Up'),\n                                placement: 'top-end'\n                              }"}],staticClass:"ml-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.subir(item)}}},[_c('CIcon',{attrs:{"name":"cil-arrow-circle-top"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.delete')+_vm.$t('label.circuit')+' '+_vm.$t('label.modality'),
                                placement: 'top-end'
                              }),expression:"{\n                                content: $t('label.delete')+$t('label.circuit')+' '+$t('label.modality'),\n                                placement: 'top-end'\n                              }"}],staticClass:"ml-1",attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteR(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }